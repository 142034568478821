import React from 'react';
import { graphql } from 'gatsby';
import { Link } from '@chakra-ui/react';

import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import Layout from '../../../components/atoms/layout';
import PageTitle from '../../../components/PageTitle';
import { Question } from '../../../template/legal/styles';
import TermOfUseProps from '../../../interface/template';
import { Text2, Text3 } from '../../../components/atoms/body';
import withI18next from '../../../i18n/TemplateI18n';

const PrivacyPolicy: React.SFC<any> = (props: TermOfUseProps) => {
  const { pageContext } = props;
  const { url, pageName } = pageContext;

  return (
    <>
      <Header pageName={pageName} showBackground url={url}>
        <PageTitle subtitle="" title="Privacy Policy" />
      </Header>
      <Layout>
        <Question>
          <Text3 as="p">
            <strong>POLITIQUE DE CONFIDENTIALITÉ</strong>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">IDENTIFICATION</Text2>
          <Text3 as="p">
            <ul>
              <li>
                <strong>Responsable du traitement </strong>: Ironhack Group INC
                Sucursal en España (ci-après, « Ironhack »)
              </li>
              <li>
                <strong>CIF : </strong>W4009352H
              </li>
              <li>
                <strong>Siège social </strong>: C/ CASTELLO, 115, BAJOS 7 -
                28006 MADRID
              </li>
              <li>
                <strong>Délégué à la protection des données (DPO) </strong>:
                L’utilisateur peut contacter le DPO par courrier postal envoyé
                au siège social d’Ironhack à l’attention du «{' '}
                <strong>Délégué à la protection des données</strong> » ou par
                courrier électronique à{' '}
                <a href="mailto:legal@ironahack.com">legal@ironahack.com</a>
              </li>
            </ul>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">INFORMATIONS ET CONSENTEMENT</Text2>
          <Text3 as="p">
            En acceptant cette Politique de Confidentialité, l'utilisateur est
            informé et donne son consentement éclairé, exprès, libre et sans
            équivoque afin que les données personnelles fournies par le biais
            des formulaires sur le site web situé à l'URL{' '}
            <a href="https://www.ironhack.com/fr">www.ironhack.com</a> (ci-après
            le « site web ») soient traitées par Ironhack.
          </Text3>
          <Text3 as="p">
            Ironhack n’est pas responsable du traitement des données effectué
            par le biais de sites Web qui ne relèvent pas de la responsabilité
            d'Ironhack.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">
            CARACTÈRE OBLIGATOIRE DE LA COMMUNICATION DES DONNÉES
          </Text2>
          <Text3 as="p">
            Les données demandées dans les formulaires du site Web sont, en
            général, obligatoires (sauf indication contraire dans le champ
            requis) dans le but de répondre aux finalités établies. Par
            conséquent, si elles ne sont pas fournies ou ne sont pas fournies
            correctement, les demandes faites par l'utilisateur ne peuvent pas
            être traitées, sans préjudice du fait que le contenu du site web
            puisse être librement consulté.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">
            QUELLE FIN IRONHACK TRAITETELLE LES DONNÉES PERSONNELLES DE
            LUTILISATEUR ET POUR QUELLE DURÉE
          </Text2>
          <Text3 as="p">
            Les données personnelles fournies par l'utilisateur{' '}
            <strong>via le formulaire de contact du site web</strong> sont
            traitées par Ironhack aux fins suivantes :
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Traiter les questions, suggestions et réclamations mises à la
                connaissance d’Ironhack par l'utilisateur. Vos données sont
                conservées à cette fin pendant toute la durée de la gestion de
                votre demande, suggestion et/ou réclamation et par la suite,
                jusqu'à expiration de toute responsabilité dérivée de la
                demande.
              </li>
            </ul>
            Les données personnelles fournies{' '}
            <strong>via l'abonnement au bulletin commercial du site Web</strong>{' '}
            sont traitées par Ironhack aux fins suivantes :
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Exécuter l’abonnement au bulletin commercial afin de tenir
                l'utilisateur informé des dernières nouvelles et événements
                relatifs à Ironhack. Vos données sont conservées jusqu'à ce que
                vous retiriez votre consentement.
              </li>
            </ul>
            Les données personnelles fournies via le{' '}
            <strong>formulaire d'inscription au cours d’Ironhack</strong> sont
            traitées par Ironhack aux fins suivantes :
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Effectuer la gestion de votre inscription, ainsi que les actions
                nécessaires à la sélection des étudiants. Vos données sont
                conservées pendant toute la durée de la gestion de votre
                inscription et par la suite, jusqu'à expiration de toute
                responsabilité dérivée de l’inscription.
              </li>
              <li>
                Si vous avez coché la case correspondante, Exécuter l’abonnement
                au bulletin commercial afin de vous tenir informé des dernières
                nouvelles et événements d'Ironhack. Vos données sont conservées
                jusqu'à ce que vous retiriez votre consentement.
              </li>
            </ul>
            Les données personnelles fournies{' '}
            <strong>
              via le formulaire d'inscription en tant que société collaboratrice
              d’Ironhack
            </strong>{' '}
            sont traitées par Ironhack aux fins suivantes :
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Exécuter l’inscription de l'entreprise pour laquelle vous
                travaillez en tant que société collaboratrice d’Ironhack. Vos
                données sont conservées pendant toute la durée de la gestion de
                l'entreprise en tant que société collaboratrice et par la suite,
                jusqu'à expiration de toute responsabilité dérivée de
                l’inscription.
              </li>
            </ul>
            Les données personnelles fournies{' '}
            <strong>
              via le formulaire d'inscription à une offre d'emploi{' '}
            </strong>
            sont traitées par Ironhack aux fins suivantes :
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Évaluer votre candidature dans le cadre de la sélection et, le
                cas échéant, prendre les mesures nécessaires pour l'intégrer au
                processus de sélection, ainsi qu'aux processus à venir
                susceptibles de vous intéresser. Sauf indication contraire, vos
                données sont conservées pendant une durée d'un an à compter de
                votre inscription à l'offre. Il relève donc de votre
                responsabilité de maintenir ces données à jour, étant donné
                qu’elles peuvent être utilisées pour vous informer des offres
                d’Ironhack susceptibles de vous intéresser.
              </li>
            </ul>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">
            QUELLES SONT LES DONNÉES CARACTÈRE PERSONNEL TRAITÉES PAR IRONHACK
          </Text2>
          <Text3 as="p">
            Par rapport au formulaire de contact disponible sur le site web,
            Ironhack traite les données suivantes :
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Données d'identification : nom, prénom, etc.</li>
              <li>Coordonnées : adresse e-mail, numéro de téléphone, etc.</li>
            </ul>
            Par rapport au formulaire d'abonnement au bulletin commercial
            disponible sur le site Web, Ironhack traite les données suivantes :
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Coordonnées : adresse e-mail, etc.</li>
            </ul>
            Par rapport au formulaire d'inscription aux cours d’Ironhack
            disponible sur le site Web, Ironhack traite les données suivantes :
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Données d'identification : nom, prénom, etc.</li>
              <li>Coordonnées : adresse e-mail, numéro de téléphone, etc.</li>
            </ul>
            Par rapport au formulaire d'inscription en tant que société
            collaboratrice d'Ironhack disponible sur le site Web, Ironhack
            traite les données suivantes :
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Données d'identification : nom, prénom, etc.</li>
              <li>Données relatives à l’emploi : poste.</li>
              <li>Coordonnées : adresse e-mail, numéro de téléphone, etc.</li>
            </ul>
            Par rapport au formulaire d'inscription à une offre d'emploi,
            Ironhack traite les données suivantes :
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Données d'identification : nom, prénom, image, NIF, adresse,
                etc.
              </li>
              <li>Coordonnées : numéro de téléphone, adresse e-mail, etc.</li>
              <li>
                Données d’ordre académique et professionnel :
                formation/diplômes, parcours de l’étudiant, expérience
                professionnelle, appartenance à des associations ou des ordres
                professionnels.
              </li>
              <li>
                Détails en matière d’emploi : profession, poste de travail,
                données non économiques sur le salaire, parcours de l’employé.
              </li>
              <li>Autres données susceptibles d’être indiquées dans le CV.</li>
            </ul>
            Dans le cas où l'utilisateur fournirait des données de tiers, il
            déclare disposer de leur consentement et s'engage à leur transmettre
            les informations contenues dans la politique en matière de
            confidentialité, dégageant Ironhack de toute responsabilité à ce
            sujet. Néanmoins, Ironhack peut effectuer des vérifications
            régulières afin de constater ces faits, en appliquant les
            obligations de vigilance correspondantes, conformément à la
            réglementation sur la protection des données.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">POLITIQUE EN MATIÈRE DE COOKIES</Text2>
          <Text3 as="p">
            Cliquez{' '}
            <Link href="https://cdn.ironhack.com/marketing/IH_Coookies_Policy.docx">
              ici
            </Link>{' '}
            pour voir notre politique de cookies
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">
            QUELLE EST LA LÉGITIMITÉ DU TRAITEMENT DES DONNÉES CARACTÈRE
            PERSONNEL DE LUTILISATEUR
          </Text2>
          <Text3 as="p">
            <ul>
              <li>
                Le traitement des données relatif à la gestion des questions,
                suggestions et/ou réclamations est basé sur le consentement
                donné par l'utilisateur au moment de sa demande.
              </li>
              <li>
                Le traitement des données relatif à l'abonnement au bulletin
                commercial afin d'être informé des dernières nouvelles et
                événements d'Ironhack est basé sur le consentement donné par
                l'utilisateur au moment de sa demande.
              </li>
              <li>
                Le traitement des données relatif à la demande d'inscription aux
                cours d’Ironhack est basé sur l'exécution de la relation
                contractuelle entre Ironhack et l'utilisateur. Par conséquent,
                il ne nécessite pas le consentement de l'utilisateur.
              </li>
              <li>
                Le traitement des données relatif à la demande d'inscription en
                tant que société collaboratrice d’Ironhack est basé sur
                l'exécution de la relation contractuelle. Par conséquent, il ne
                nécessite pas le consentement de l'utilisateur.
              </li>
              <li>
                Le traitement des données relatif à la demande d'inscription à
                des offres d'emploi est basé sur le consentement de
                l'utilisateur.
              </li>
            </ul>
            Veuillez noter que vous pouvez retirer votre consentement à tout
            moment et que ceci n’aura pas pour effet de compromettre la licéité
            du traitement effectué avant ledit retrait.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">DESTINATAIRES</Text2>
          <Text3 as="p">Vos données peuvent être communiquées :</Text3>
          <Text3 as="p">
            <ul>
              <li>
                Aux administrations publiques dans les cas prévus par la loi.
              </li>
              <li>
                Aux tribunaux et organismes de sécurité dans les cas prévus par
                la loi.
              </li>
              <li>
                Aux services dédiés au traitement des informations à des fins de
                marketing :
                <ul>
                  <li>Mailchimp</li>
                  <li>Pipedrive</li>
                  <li>Google Analytics</li>
                  <li>Hotjar</li>
                  <li>Facebook Custom Audience</li>
                </ul>
              </li>
            </ul>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">TRANSFERTS INTERNATIONAUX</Text2>
          <Text3 as="p">
            Les informations collectées peuvent être transférées à :
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Ironhack campuses au Mexique, aux États-Unis et au Brésil</li>
              <li>Mailchimp Inc. (États-Unis)</li>
            </ul>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">RESPONSABILITÉ DE LUTILISATEUR</Text2>
          <Text3 as="p">L’utilisateur :</Text3>
          <Text3 as="p">
            <ul>
              <li>
                Garantit avoir plus de 18 ans et fournir à Ironhack des données
                vraies, exactes, complètes et à jour. A cet effet, l'utilisateur
                est responsable de la véracité de toutes les données qu'il
                communique et doit maintenir à jour les informations fournies,
                de manière à ce qu'elles correspondent à sa situation réelle.
              </li>
              <li>
                Garantit avoir informé les tiers concernés qu’il fournissait
                leurs données à Ironhack, et, dans ce cas, avoir mis à leur
                connaissance les aspects contenus dans ce document. Il garantit
                également avoir obtenu leur autorisation pour que leurs données
                soient fournies à Ironhack aux fins indiquées.
              </li>
              <li>
                Être responsable de toute information fausse ou inexacte qu’il
                fournirait via le site Web et de tout dommage direct ou indirect
                que cela entraînerait à Ironhack ou à des tiers.
              </li>
            </ul>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">COMMUNICATIONS COMMERCIALES</Text2>
          <Text3 as="p">
            L'une des fins auxquelles Ironhack traite les données personnelles
            fournies par les utilisateurs est de leur envoyer des communications
            électroniques contenant des informations sur les produits, services,
            promotions, offres, événements ou nouvelles susceptibles de les
            intéresser. Les communications de ce type sont effectuées uniquement
            et exclusivement si les utilisateurs ont expressément donné leur
            consentement à cet effet.
          </Text3>
          <Text3 as="p">
            Si l'utilisateur souhaite ne plus recevoir de communications
            commerciales ou promotionnelles de la part d'Ironhack, il peut
            demander à se désinscrire du service en envoyant un e-mail à
            l'adresse suivante :{' '}
            <a href="mailto:legal@ironhack.com">legal@ironhack.com</a>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">DROITS DE LUTILISATEUR</Text2>
          <Text3 as="p">
            L'utilisateur peut, à tout moment et gratuitement, contacter
            Ironhack en envoyant un courrier postal à l'adresse Calle Castello,
            115B, Local 7, 28006, Madrid, avec la référence « Protection des
            données » ou par e-mail à l'adresse{' '}
            <a href="mailto: legal@ironhack.com">legal@ironhack.com</a> en
            joignant une copie de sa pièce d'identité, pour :
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Révoquer les consentements accordés.</li>
              <li>
                Obtenir la confirmation qu’Ironhack traite ou non des données
                personnelles concernant l'utilisateur.
              </li>
              <li>Accéder à ses données personnelles.</li>
              <li>Rectifier des données inexactes ou incomplètes.</li>
              <li>Demander que ses données soient supprimées.</li>
              <li>
                Obtenir d'Ironhack la limitation du traitement de ses données
                lorsque l'une des conditions prévues dans la réglementation sur
                la protection des données est remplie.
              </li>
              <li>
                Dans certaines circonstances et pour des raisons liées à la
                situation particulière de l’utilisateur par rapport au
                traitement de ses données (en particulier, à des fins
                commerciales de la part d’Ironhack), il peut s’opposer au
                traitement de ses données. Ironhack cessera de traiter les
                données, sauf pour des raisons impérieuses et légitimes, ou pour
                l'exercice ou la défense d'éventuelles réclamations.
              </li>
              <li>
                Obtenir une intervention humaine pour exprimer son point de vue
                et contester les décisions automatisées adoptées par Ironhack.
              </li>
              <li>Demander la portabilité de ses données.</li>
              <li>
                Formuler une réclamation concernant la protection de ses données
                personnelles auprès de l'autorité de contrôle correspondante
                (dans le cas de l'Espagne, l'Agence espagnole de protection des
                données).
              </li>
            </ul>
            L'exercice des droits susmentionnés est conditionné au respect des
            exigences prévues par la réglementation en vigueur à chaque instant
            vous concernant.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">MESURES DE SÉCURITÉ</Text2>
          <Text3 as="p">
            Ironhack traite les données de l'utilisateur à tout moment de
            manière confidentielle et respecte l'obligation de confidentialité
            obligatoire les concernant, conformément aux dispositions de la
            réglementation applicable, en adoptant à cet effet les mesures
            techniques et organisationnelles nécessaires visant à assurer la
            sécurité des données et empêcher qu’elles ne soient modifiées,
            perdues, traitées ou accessibles sans autorisation, en tenant compte
            de l'état des technologies, de la nature des données stockées et des
            risques auxquels elles sont exposées.
          </Text3>
          <Text3 as="p">Dernière mise à jour : 10/08/2018</Text3>
          <Text3 as="p">
            <strong>
              Copyright © 2018 Ironhack School S.L. Tous droits réservés.{' '}
            </strong>
          </Text3>
        </Question>
      </Layout>
      <Footer page={pageName} />
    </>
  );
};

export const query = graphql`
  query {
    locales: allLocale(
      filter: {
        lng: { eq: "fr" }
        ns: { regex: "/(seo)|(breadcrumbs)|(menu)|(banner)/" }
      }
    ) {
      ...TranslationFragment
    }
  }
`;

export default withI18next('fr')(PrivacyPolicy);
